import React, { ReactNode, useState } from 'react';

interface TooltipProps {
    text: string;
    children: ReactNode;
    position?: 'top' | 'bottom' | 'left' | 'right';
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, position = 'top' }) => {
    const [visible, setVisible] = useState(false);

    // Define base styles for tooltip and arrow
    const baseTooltipStyle: React.CSSProperties = {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        color: 'white',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        transform: 'translateX(-50%)',
        fontSize: '.7rem',
        padding: '.5rem',
        borderRadius: '10px',
    };

    const baseArrowStyle: React.CSSProperties = {
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    };

    // Adjust tooltip and arrow styles based on position
    const tooltipStyle: React.CSSProperties = {
        ...baseTooltipStyle,
        ...getTooltipPositionStyles(position),
    };

    const arrowStyle: React.CSSProperties = {
        ...baseArrowStyle,
        ...getArrowStyles(position),
    };

    function getTooltipPositionStyles(position: 'top' | 'bottom' | 'left' | 'right'): React.CSSProperties {
        switch (position) {
            case 'top':
                return {
                    bottom: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    marginBottom: '10px',
                };
            case 'bottom':
                return {
                    top: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    marginTop: '10px',
                };
            case 'left':
                return {
                    top: '50%',
                    right: '100%',
                    transform: 'translateY(-50%)',
                    marginRight: '10px',
                };
            case 'right':
                return {
                    top: '50%',
                    left: '100%',
                    transform: 'translateY(-50%)',
                    marginLeft: '10px',
                };
            default:
                return {};
        }
    }

    function getArrowStyles(position: 'top' | 'bottom' | 'left' | 'right'): React.CSSProperties {
        switch (position) {
            case 'top':
                return {
                    bottom: '-5px', // Position the arrow outside the box
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderWidth: '5px 5px 0 5px',
                    borderColor: 'rgba(0, 0, 0, 0.75) transparent transparent transparent',
                };
            case 'bottom':
                return {
                    top: '-5px', // Position the arrow outside the box
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderWidth: '0 5px 5px 5px',
                    borderColor: 'transparent transparent rgba(0, 0, 0, 0.75) transparent',
                };
            case 'left':
                return {
                    right: '-5px', // Position the arrow outside the box
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderWidth: '5px 5px 5px 0',
                    borderColor: 'transparent rgba(0, 0, 0, 0.75) transparent transparent',
                };
            case 'right':
                return {
                    left: '-5px', // Position the arrow outside the box
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderWidth: '5px 0 5px 5px',
                    borderColor: 'transparent transparent transparent rgba(0, 0, 0, 0.75)',
                };
            default:
                return {};
        }
    }

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {children}
            {visible && (
                <>
                    <div style={tooltipStyle} className={'text-xs p-2 rounded-3xl'}>
                        {text}
                        <div style={arrowStyle}></div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Tooltip;
