import React from "react";
import Layout from "../../screen/layout";

const TermsOfUse = () => {
  return (
    <Layout>
      <p>Terms of Use</p>
    </Layout>
  );
};

export default TermsOfUse;
