import React, { useState, useRef } from 'react';
import Input from '../../atomic/input';

const OTPInput = ({ numDigits = 5, onComplete }:{numDigits: number; onComplete: (otp: string) => void;}) => {
  const initialCode = Array(numDigits).fill('');
  const [code, setCode] = useState(initialCode);
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move focus to next input
      if (index < numDigits - 1) {
        inputsRef.current[index + 1]?.focus();
        onComplete('')
      } else if (index === numDigits - 1) {
        // If the last input is filled, trigger the onComplete function
        onComplete(newCode.join(''));
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      if (code[index]) {
        // If there is a value in the current input, clear it
        const newCode = [...code];
        newCode[index] = '';
        setCode(newCode);
      } else if (index > 0) {
        // Move focus to the previous input on Backspace if the current input is empty
        inputsRef.current[index - 1]?.focus();
      }
      onComplete('')
    }
  };

  return (
    <div className="grid grid-cols-5 gap-3">
      {code.map((digit, index) => (
        <Input
          key={index}
          ref={(el: HTMLInputElement | null) => (inputsRef.current[index] = el)}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
          maxLength={1}
          className="!text-center w-10 !p-2"
        />
      ))}
    </div>
  );
};

export default OTPInput;
