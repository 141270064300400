import React, {useEffect} from "react";
import Button from "../../atomic/buttons/button/button";
import IconButton from "../../atomic/buttons/icon-button";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Logo from "../../../assets/images/logo/logo.png";
import ProfileCard from "../profile-card/ProfileCard";
import GlobeIcon from "../../../assets/icons/globe.svg";
import NotificationsIcon from "../../../assets/icons/notification.svg";
import ShopsIcon from "../../../assets/icons/shop.svg";
import TieIcon from "../../../assets/icons/tie.svg";
import useUsers from "../../../hooks/useUsers";
import Spinner from "../../atomic/spinner";

const Header = () => {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {getUserProfile, userProfile, responseGetApi} = useUsers();

    const onClickChangeLanguageButton = () => {
        try {
            let currentLanguage = i18n.language;
            i18n.changeLanguage(currentLanguage === "fa" ? "en" : "fa");
        } catch (error) {
        }
    };

    useEffect(() => {
        getUserProfile();
    }, []);

    return (
        <div className="h-[10vh] w-full bg-white text-black flex justify-around gap-3 items-center">
            <Link to="/" className="flex gap-2 items-center no-underline">
                <img src={Logo} alt={"logo"} className="h-[24px]"/>
                <h1 className="font-bold text-[2rem] leading-none pt-2">iSketch</h1>
            </Link>
            <div className="flex gap-3">
                {responseGetApi?.isLoading ? (
                    <div className={'min-w-3 flex items-center'}><Spinner/></div>) : userProfile ? (
                    <>

                            <IconButton
                                icon={
                                    <img
                                        src={NotificationsIcon}
                                        alt={"arrow down"}
                                        className="w-5 h-5 rounded-full"
                                    />
                                }
                                onClick={() => {
                                    onClickChangeLanguageButton();
                                }}
                                disabled={true}
                                tipContent={t("notifications")}
                            />
                        {!location.pathname.includes("/shops") &&
                                <IconButton
                                    icon={
                                        <img
                                            src={ShopsIcon}
                                            alt={"arrow down"}
                                            className="w-5 h-5 rounded-full"
                                        />
                                    }
                                    onClick={() => {
                                        navigate('/shops');
                                    }}
                                    tipContent={t("shops")}
                                /> }
                        {!location.pathname.includes("/dressing-room") &&
                                <IconButton
                                    icon={
                                        <img
                                            src={TieIcon}
                                            alt={"arrow down"}
                                            className="w-5 h-5 rounded-full"
                                        />
                                    }
                                    onClick={() => {
                                        navigate('/dressing-room');
                                    }}
                                    tipContent={t("dressing_room")}
                                />}
                        <ProfileCard user={userProfile} getUserProfile={getUserProfile}/>

                    </>
                ) : (
                    !location.pathname.includes("/authorization") && (
                        <Button
                            title={t("authorize")}
                            onClick={() => {
                                navigate("/authorization");
                            }}
                            variety={"dashed"}
                        />
                    )
                )}

                <IconButton
                    title={i18n.language === "en" ? "En" : "فا"}
                    icon={
                        <img
                            src={GlobeIcon}
                            alt={"arrow down"}
                            className="w-5 h-5 rounded-full"
                        />
                    }
                    onClick={() => {
                        onClickChangeLanguageButton();
                    }}
                />
            </div>
        </div>
    );
};
export default Header;
