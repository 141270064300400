import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../atomic/buttons/button";
import { useDispatch } from "react-redux";
import {
  setDisplayModal,
  setModalType,
} from "../../../../redux/slices/displayModalsSlice";
import OTPInput from "../../otp-input";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import useAuth from "../../../../hooks/useAuth";

const VerifyActionByOTPModal = () => {
  const { t, i18n } = useTranslation();
  const modalData = useSelector(
    (state: RootState) => state?.modalsManagement.data
  );

  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const { verify, responsePostApi } = useAuth();

  const verifyRegistration = async () => {
    try {
      let result = await verify(
        (modalData as verificationModalDataObject)?.token,
        code
      );
      if (result) {
        dispatch(setModalType("complete-profile"));
        dispatch(setDisplayModal(true));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(code !== ''){
      verifyRegistration()
    }
  }, [code]);

  return (
    <div className="flex items-center justify-center flex-col gap-y-8 bg-white rounded-3xl shadow-lg p-10">
      <p className="text-md text-black font-bold text-center m-0">
        {t("verification_account")}
      </p>
      <div className="text-center text-gray-500 text-sm">
        <p className="m-0">
          {t("verification_account_message_start")}
          <span className="block font-semibold my-1">
            {
              (modalData as verificationModalDataObject)
                ?.emailAddressOrPhoneNumber
            }
          </span>
          {t("verification_account_message_end")}
        </p>
      </div>
      <OTPInput numDigits={5} onComplete={setCode} />
      <div className={`flex gap-3`} style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}>
        <Button
          variety="secondary"
          title={t("cancel")}
          onClick={() => dispatch(setDisplayModal(false))}
        />
        <Button
            title={t("verification_account")}
            onClick={() => verifyRegistration()}
            disabled={code === ''}
            loading={responsePostApi?.isLoading}
            customClassName={'w-[162px]'}
        />
      </div>
    </div>
  );
};

export default VerifyActionByOTPModal;
