import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../atomic/buttons/button";
import {CSSTransition} from "react-transition-group";
import FadeInText from "../../atomic/text/fade-in-text";
import BodyPartChip from "../../composite/body-part-chip";
import BodySizeSlider from "../../composite/body-size-slider";
import {setDisplayModal, setModalData, setModalType} from "../../../redux/slices/displayModalsSlice";
import {useDispatch} from "react-redux";
import IconButton from "../../atomic/buttons/icon-button";
import QuestionMarkIcon from '../../../assets/icons/question-mark.svg';
import useDressingRoom from "../../../hooks/useDressingRoom";

const BodySizes = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {getUserBodyMeasurements, userBodyMeasurements, updateUserBodyMeasurements} = useDressingRoom();
    const [displayBodyPartsOptions, setDisplayBodyPartsOptions] = useState(true);
    const [displayUpperBodySizingSection, setDisplayUpperBodySizingSection] = useState(false);
    const [selectedItems, setSelectedItems] = useState<BodyMeasurementItem[]>([]);
    const [selectedBodyRegion, setSelectedBodyRegion] = useState({name: ''})

    useEffect(() => {
        getUserBodyMeasurements();
    }, []);

    return (
        <div
            className={`p-6 ${i18n.language === "fa" ? "text-right" : "text-left"}`}
        >
            <div className="min-h-[140px]"
                 style={{direction: i18n?.language === 'fa' ? 'rtl' : 'ltr'}}>
                <div className={'flex gap-1 items-center'}>
                    <h1 className="font-bold text-lg pt-1">{t("body_sizes")}</h1>
                    <IconButton
                        onClick={() => {
                            dispatch(setDisplayModal(true));
                            dispatch(setModalType('guideline'));
                            dispatch(setModalData(
                                    {
                                        title: t('measurement_guideline_title'),
                                        description: t('measurement_guideline_description'),
                                        tips: t('measurement_guideline_tips', {returnObjects: true})
                                    }
                                )
                            )
                        }}
                        icon={<img src={QuestionMarkIcon} alt={'help'}/>}
                    />
                </div>
                <div className="text-sm flex flex-col gap-y-2">
                    <FadeInText>
                        <p>
                            {t("body_sizes_description_1")}
                        </p>
                    </FadeInText>
                    <FadeInText delay={.2}>
                        <p>
                            {t("body_sizes_description_2")}
                        </p>
                    </FadeInText>
                    <FadeInText delay={.4}>
                        <p className={'font-bold'}>
                            {t("body_sizes_description_3")}
                        </p>
                    </FadeInText>
                </div>
            </div>

            <CSSTransition
                in={displayBodyPartsOptions}
                timeout={300}
                classNames="fade"
                unmountOnExit
                onExited={() => setDisplayUpperBodySizingSection(true)}
            >
                <div className={'grid grid-cols-4 gap-2'} style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}>
                    {
                        userBodyMeasurements?.body_measurements?.map((br, index) =>
                            <FadeInText key={index} delay={.4}>
                                <BodyPartChip
                                    key={index}
                                    onClickHandle={() => {
                                        setSelectedItems(br?.items)
                                        setSelectedBodyRegion({name: br?.name})
                                        setDisplayBodyPartsOptions(false)
                                    }}
                                    percentage={
                                        Math.round(br?.items?.reduce(
                                            (totalValue, currentValue) =>
                                                totalValue + (currentValue.percentage * (currentValue?.value !== 0 ? 1 : 0)),
                                            0
                                        ))
                                    }
                                    label={`${i18n?.language === 'en' ? br.en_name : br.fa_name}`}
                                    description={`${i18n?.language === 'en' ? br.en_description : br.fa_description}`}
                                />
                            </FadeInText>
                        )
                    }
                </div>
            </CSSTransition>

            <CSSTransition
                in={displayUpperBodySizingSection}
                timeout={300}
                classNames="fade"
                unmountOnExit
                onExited={() => setDisplayBodyPartsOptions(true)}
            >
                <>
                    <div className="flex w-full gap-2 justify-center">
                        <Button
                            onClick={() => setDisplayUpperBodySizingSection(false)}
                            variety={'primary'}
                            title={t('finish')}
                        />
                    </div>
                    <BodySizeSlider
                        items={selectedItems}
                        selectedBodyRegion={selectedBodyRegion}
                        updateUserBodyMeasurements={updateUserBodyMeasurements}
                    />
                </>
            </CSSTransition>
        </div>
    );
};

export default BodySizes;
