import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LoadingModal from "../../composite/modals/loading-modal";
import CompleteProfileModal from "../../composite/modals/complete-profile-modal";
import VerifyActionByOTPModal from "../../composite/modals/verify-action-by-otp-modal/VerifyActionByOtpModal";
import GuidelineModal from "../../composite/modals/guideline-modal";

const ModalLayout = () => {
  const modalsManagement = useSelector(
    (state: RootState) => state?.modalsManagement
  );

  const giveMeModalContent = () => {
    switch (modalsManagement?.modalType) {
      case "loading":
        return <LoadingModal />;
      case "complete-profile":
        return <CompleteProfileModal />;
      case "verify-register-by-otp":
        return <VerifyActionByOTPModal />;
      case "guideline":
        return <GuidelineModal />;
      default:
        break;
    }
  };

  return modalsManagement.displayModal === false ? null : (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-75 flex items-center justify-center z-50">
      {giveMeModalContent()}
    </div>
  );
};

export default ModalLayout;
