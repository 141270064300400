import React, {useState, useRef, useEffect} from 'react';
import './styles.css';
import MeterPointerIcon from '../../../assets/icons/meter-pointer.svg';
import RevertIcon from '../../../assets/icons/revert.svg';
import ConfirmIcon from '../../../assets/icons/confirm.svg';
import FadeLayout from "../fade-layout";
import Tooltip from "../../atomic/tooltip";
import {useTranslation} from "react-i18next";

const MeterInput = (
    {
        value,
        onChange,
        label
    }: {
        value: number;
        onChange: (value: number) => Promise<boolean>;
        label?: string;
    }) => {
    const {t} = useTranslation();
    const [inputValue, setInputValue] = useState(value);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [displayActionButtons, setDisplayActionButtons] = useState(false);

    const MIN_VALUE = 0; // Minimum value to display
    const MAX_VALUE = 200; // Maximum value to display
    const PIXELS_PER_UNIT = 10; // Pixels per unit

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollRef.current) {
            // Prevent the page from scrolling when scrolling inside the div
            event.preventDefault();

            // Scroll horizontally based on vertical scroll input
            scrollRef.current.scrollLeft += event.deltaY;

            const scrollCenter = scrollRef.current.scrollLeft + scrollRef.current.clientWidth / 2;
            const newValue = Math.round((scrollCenter / PIXELS_PER_UNIT + MIN_VALUE) * 10) / 10;
            if (newValue !== inputValue) {
                const formattedValue = parseFloat((newValue / 10 + MIN_VALUE).toFixed(1));
                setInputValue(formattedValue);
                setDisplayActionButtons(true);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedValue = Number(event.target.value);
        if (updatedValue !== inputValue) {
            const formattedValue = parseFloat((updatedValue + MIN_VALUE).toFixed(1));
            setInputValue(formattedValue);
        }
    };

    const onClickConfirmButton = () => {
        try {
            let result = onChange(inputValue);
            if (result)
                setDisplayActionButtons(false);
        } catch (error) {
            console.error(error);
        }
    }

    const onkeydownHandle = (event: React.KeyboardEvent<HTMLInputElement>) => {
        try {
            if (scrollRef.current && event.key === 'Enter') {
                const scrollPosition = (value * 10 - MIN_VALUE) * PIXELS_PER_UNIT - scrollRef.current.clientWidth / 2 + PIXELS_PER_UNIT / 2;

                scrollRef.current.scrollTo({
                    left: scrollPosition,
                    behavior: 'smooth'
                });
            }
        } catch (error) {
            error && console.error(error);
        }
    }

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = (inputValue - MIN_VALUE) * PIXELS_PER_UNIT - scrollRef.current.clientWidth / 2 + PIXELS_PER_UNIT / 2;
        }
    }, []);

    const updateValue = (value: number) => {
        try {
            if ((value || value === 0) && !!scrollRef.current) {

                setInputValue(value);
                const newScrollPosition = (value * 10 - MIN_VALUE) * PIXELS_PER_UNIT - scrollRef.current.clientWidth / 2 + PIXELS_PER_UNIT / 2;
                scrollRef.current.scrollTo({
                    left: newScrollPosition,
                    behavior: 'smooth',
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        updateValue(value)
    }, [value]);

    useEffect(() => {
        const divElement = scrollRef.current;
        if (divElement) {
            // Add event listener to handle vertical scrolling
            // @ts-ignore
            divElement.addEventListener('wheel', handleScroll, {passive: false});

            // Cleanup event listener on component unmount
            return () => {
                // @ts-ignore
                divElement.removeEventListener('wheel', handleScroll);
            };
        }
    }, []);

    return (
        <div
            className={'flex flex-col items-center w-full my-4'}
        >
            <div className={'flex justify-center items-start gap-2 w-full'}>
                <FadeLayout duration={0.5} visible={displayActionButtons}>
                    <Tooltip text={t('previous_value')} position={'top'}>
                        <button
                            className={`rounded-full text-[.8rem] w-fit p-1 transition duration-300 min-w-8 text-lg hover:bg-green-100 items-center justify-center flex mt-2`}
                            onClick={() => {
                                updateValue(value);
                                setDisplayActionButtons(false);
                            }}
                        >
                            <img src={RevertIcon} alt={'revert-icon'}/>
                        </button>
                    </Tooltip>
                </FadeLayout>

                <div
                    className={'flex justify-center items-center text-center text-green-700 w-fit mb-8 p-2 rounded-3xl bg-emerald-50'}>
                    <input
                        type="number"
                        value={inputValue}
                        onChange={handleInputChange}
                        min={MIN_VALUE}
                        max={MAX_VALUE}
                        step={0.5}
                        className={'meter_input text-center text-xl font-bold w-fit p-0 focus:outline-none bg-transparent'}
                        onKeyDown={onkeydownHandle}
                    />
                    <p className={'text-xs text-start text-green-600 rounded-2xl'}>cm</p>
                </div>

                <FadeLayout duration={0.5} visible={displayActionButtons}>
                    <Tooltip text={t('submit')} position={'top'}>
                        <button
                            className={`rounded-full text-[.8rem] w-fit p-1 transition duration-300 min-w-8 text-lg hover:bg-green-100 items-center justify-center flex mt-2`}
                            onClick={() => {
                                onClickConfirmButton();
                            }}
                        >
                            <img src={ConfirmIcon} alt={'confirm-icon'}/>
                        </button>
                    </Tooltip>
                </FadeLayout>
            </div>


            <div
                className="w-4/5 relative mb-2.5 whitespace-nowrap"
            >
                <div
                    ref={scrollRef}
                    // onScroll={() => handleScroll()}
                    className="meter w-full h-[70px] overflow-x-scroll overflow-y-hidden relative"
                >
                    <div style={{
                        width: `${(MAX_VALUE - MIN_VALUE) * PIXELS_PER_UNIT + PIXELS_PER_UNIT}px`,
                        position: 'relative',
                        height: '100%'
                    }}>
                        {[...Array((MAX_VALUE - MIN_VALUE) * 10 + 1)].map((_, i) => (
                            <div
                                key={i}
                                style={{
                                    display: 'inline-block',
                                    width: '1px', // 1 unit width
                                    background: i % 10 === 0 ? '#000' : '#ccc',
                                    height: i % 10 === 0 ? '60%' : i % 10 === 5 ? '50%' : '30%',
                                    verticalAlign: 'top',
                                    marginLeft: i === 0 ? 0 : '9px',
                                    position: 'relative',
                                }}
                            >
                                {i % 10 === 0 && (
                                    <p
                                        style={{
                                            position: 'absolute',
                                            top: '100%',
                                            left: i / 10 === 0 ? '0px' : i / 10 < 10 ? '-3px' : '-6px',
                                            transform: 'translateY(5px)',
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {(i / 10 + MIN_VALUE).toFixed(0)}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="absolute left-1/2 border-l-2 border-l-green-500 h-14 bottom-6 transform -translate-x-1/2 pointer-events-none"
                >
                </div>
                <img
                    alt={'meter-pointer-icon'}
                    src={MeterPointerIcon}
                    className="absolute left-1/2 transform -translate-y-[6.4rem] -translate-x-1/2 pointer-events-none text-green-500"
                />
            </div>
            <div style={{marginBottom: '10px'}}>{label}</div>
        </div>
    );
};

export default MeterInput;
