export const isEmailOrIranianPhoneNumber = (input: string): {
    result: boolean,
    type: 'email' | 'phone' | 'invalid'
} => {
    // Basic regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Regex for Iranian mobile number validation
    const iranianPhoneRegex = /^(?:\+98|0)?9\d{9}$/;

    if (emailRegex.test(input)) {
        return {result: true, type: 'email'};
    } else if (iranianPhoneRegex.test(input)) {
        return {result: true, type: 'phone'};
    } else {
        return {result: false, type: 'invalid'};
    }
}