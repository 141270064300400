import React from "react";
import IconButton from "../../atomic/buttons/icon-button";
import DownArrowIcon from "../../../assets/icons/down-arrow.svg";
import { useTranslation } from "react-i18next";

const SidebarButton = ({
  text,
  icon,
  expandedItems = null,
  onClick,
  customClass,
  selected,
    disabled = false,
}: {
  text: string;
  icon: any;
  expandedItems?: any;
  onClick: any;
  customClass?: string;
  selected?: boolean;
  disabled?: boolean;
}) => {
  const { i18n } = useTranslation();
  return (
    <button
      className={`flex gap-2 rounded-lg w-full justify-between  px-3 py-1  ${
        i18n.language === "fa" ? "flex-row-reverse" : "flex-row"
      }  ${
        selected ? "bg-pink-100" : "bg-transparent"
      } ${
        disabled ? 'opacity-50' : 'hover:bg-slate-100'
      } ${customClass}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={`flex gap-1 items-center  ${
          i18n.language === "fa" ? "flex-row-reverse" : "flex-row"
        } `}
      >
        {icon}
        <h3 className="text-[.8rem] leading-none pt-1">{text}</h3>
      </div>
      {expandedItems && (
        <IconButton
          icon={
            <img
              src={DownArrowIcon}
              alt={"arrow down"}
              className="w-4 h-4 rounded-full"
            />
          }
          onClick={() => {
            console.log("hi");
          }}
          customClass="!p-1 min-w-0"
          disabled={disabled}
        />
      )}
    </button>
  );
};

export default SidebarButton;
