import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReAuth from "./baseQueryWithReAuth";

/**
 * Creates a generalized API service.
 * @param {string} reducerPath - The unique key to represent this API in the store.
 * @param {string} baseUrl - The base URL for API requests.
 * @returns {object} - The created API service.
 */
export const createGeneralApiCaller = ({ reducerPath } : {reducerPath: string;}) => {
    return createApi({
        reducerPath,
        baseQuery: baseQueryWithReAuth,
        endpoints: (builder) => ({
            getData: builder.query({
                query: (infoApi) => ({
                    url: `${infoApi.url}`,
                    params: infoApi.filter,
                }),
            }),
            postData: builder.mutation({
                query: (infoApi) => ({
                    url: `${infoApi.url}`,
                    method: 'POST',
                    params: infoApi.filter || undefined,
                    body: infoApi.body || undefined,
                }),
            }),
            putData: builder.mutation({
                query: (infoApi) => ({
                    url: `${infoApi.url}`,
                    method: 'PUT',
                    params: infoApi.filter || undefined,
                    body: infoApi.body || undefined,
                }),
            }),
            deleteData: builder.mutation({
                query: (infoApi) => ({
                    url: `${infoApi.url}`,
                    method: 'DELETE',
                    params: infoApi.filter || undefined,
                    body: infoApi.body || undefined,
                }),
            }),
        }),
    });
};
