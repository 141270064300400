import Recat from "react";

const NotificationCard = ({
  type,
}: {
  type: "danger" | "success" | "warning" | "info";
}) => {
  const colorClass =
    type === "danger"
      ? "border-pink-200 text-pink-600 bg-pink-100"
      : type === "success"
      ? "border-green-200 text-green-600 bg-green-100"
      : type === "warning"
      ? "border-orange-200 text-orange-600 bg-orange-100"
      : type === "info"
      ? "border-blue-200 text-blue-600 bg-blue-100"
      : "";
  return (
    <div
      className={`flex border w-full h-fit px-9 py-3 rounded-lg ${colorClass}`}
    >
      <p>This is a test</p>
    </div>
  );
};

export default NotificationCard;
