import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Input from "../../../atomic/input";
import Button from "../../../atomic/buttons/button";
import GenderImage from "../../../../assets/images/gender-image.png";
import RadioButtonGroup from "../../radio-button-group";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setDisplayModal} from "../../../../redux/slices/displayModalsSlice";
import useUsers from "../../../../hooks/useUsers";
import useToast from "../../../../hooks/useToast";
import {CSSTransition} from "react-transition-group";

const CompleteProfileModal = () => {
    const {t, i18n} = useTranslation();
    const [currentUserProfile, setCurrentUserProfile] = useState<CompleteUserProfileRequestBody>({
        password: '',
        retype_password: ''
    });
    const [searchParams] = useSearchParams();
    const signup = searchParams.get('signup');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {updateProfile, responsePutApi, getUserProfile, userProfile} = useUsers();
    const {showToast} = useToast();

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        try {
            setCurrentUserProfile(
                currentUserProfile && {...currentUserProfile, [name]: value}
            );
        } catch (error) {
        }
    };

    const onClickCompleteProfileButton = async () => {
        try {
            if (
                currentUserProfile?.password !== "" &&
                currentUserProfile?.retype_password !== ""
            ) {
                if (currentUserProfile?.password === currentUserProfile?.retype_password) {
                    let result = await updateProfile({
                        emailAddress: currentUserProfile?.email ?? '',
                        mobileNumber: currentUserProfile?.mobile_number ?? '',
                        password: currentUserProfile?.password ?? '',
                        retypePassword: currentUserProfile?.retype_password ?? '',
                        firstName: currentUserProfile?.first_name ?? '',
                        lastName: currentUserProfile?.last_name ?? '',
                        initialUpdate: true,
                    })
                    if (result
                    ) {
                        dispatch(setDisplayModal(false));
                        navigate("/");
                    }
                } else {
                    showToast(t('passwords_not_match'))
                }
            } else {
                showToast(t('passwords_empty'))
            }
        } catch (error) {
            console?.error(error);
        }
    };

    useEffect(() => {
        if (signup === 'True') {
            getUserProfile();
        }
    }, [signup]);

    useEffect(() => {
        if (userProfile) {
            setCurrentUserProfile({
                ...currentUserProfile,
                first_name: userProfile.first_name,
                last_name: userProfile.last_name
            });
        }
    }, [userProfile]);
    const [displayGenderSection, setDisplayGenderSection] = useState(true);
    const [displayInfoSection, setDisplayInfoSection] = useState(false);
    return (
        <div className="flex items-center justify-center flex-col gap-y-8 bg-white rounded-3xl shadow-lg p-10">
            <div className="text-center text-gray-500 text-sm">
                <p className="text-md text-black font-bold text-center m-0">
                    {t("complete_profile")}
                </p>
                <p className="m-0 md:my-2">{t("complete_profile_message")}</p>
            </div>
            <div className="grid md:flex">
                <CSSTransition
                    in={displayInfoSection}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                    onExited={() => setDisplayGenderSection(true)}
                >
                    <div
                        className={`flex flex-col justify-center gap-2 md:gap-12`}
                        style={{direction: i18n.language === "fa" ? "rtl" : "ltr"}}
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            <Input
                                onChange={(e) => onChangeInput(e)}
                                value={currentUserProfile?.first_name ?? ''}
                                placeholder={t("first_name")}
                                label={t("first_name")}
                                name={"first_name"}
                            />
                            <Input
                                onChange={(e) => onChangeInput(e)}
                                value={currentUserProfile?.last_name ?? ''}
                                placeholder={t("last_name")}
                                label={t("last_name")}
                                name={"last_name"}
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            <Input
                                onChange={(e) => onChangeInput(e)}
                                value={currentUserProfile?.password ?? ''}
                                placeholder={t("password")}
                                label={t("password")}
                                type="password"
                                required
                                hint={t("password_hint")}
                                name={"password"}
                            />
                            <Input
                                onChange={(e) => onChangeInput(e)}
                                value={currentUserProfile?.retype_password ?? ''}
                                placeholder={t("retype_password")}
                                label={t("retype_password")}
                                type="password"
                                required
                                hint={t("retype_password_hint")}
                                name={"retype_password"}
                            />
                        </div>
                        <div className="flex gap-3 w-full justify-center">
                            <Button
                                title={t("previous")}
                                onClick={() => {
                                    setDisplayInfoSection(false)
                                }}
                                customClassName={'w-[132px]'}
                            />
                            <Button
                                title={t("complete_profile")}
                                onClick={() => onClickCompleteProfileButton()}
                                disabled={currentUserProfile?.password === "" || currentUserProfile?.retype_password === ""}
                                loading={responsePutApi?.isLoading}
                                customClassName={'w-[132px]'}
                            />
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={displayGenderSection}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                    onExited={() => setDisplayInfoSection(true)}
                >
                    <div className={'flex flex-col justify-center items-center gap-y-8'}>
                        <div className="mx-auto mt-5">
                            <img src={GenderImage} alt={"gender"} className="w-44 sm:w-60"/>
                            <RadioButtonGroup
                                name={"Gender"}
                                options={[
                                    {label: t("female"), value: "FEMALE"},
                                    {label: t("male"), value: "MALE"},
                                ]}
                                className={`justify-between w-44 sm:w-60 ${
                                    i18n.language === "fa" ? "px-12" : "px-10 gap-8"
                                }`}
                                onChange={(genderValue) => setCurrentUserProfile(
                                    {...currentUserProfile, gender: genderValue}
                                )}
                                value={currentUserProfile?.gender ?? ''}
                            />

                        </div>
                        <Button
                            title={t("next")}
                            onClick={() => {
                                setDisplayGenderSection(false)
                            }}
                            disabled={!currentUserProfile?.gender}
                            customClassName={'w-[132px]'}
                        />

                    </div>
                </CSSTransition>

            </div>

        </div>
    );
};

export default CompleteProfileModal;
