import React, {useEffect} from "react";
import SidebarButton from "../sidebar-button";
import SignOutIcon from "../../../assets/icons/sign-out.svg";
import {useTranslation} from "react-i18next";
import DefaultProfile from "../../../assets/icons/default-profile.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import FemaleProfileBanner from "../../../assets/images/female-profile-banner.png";
import MaleProfileBanner from "../../../assets/images/male-profile-banner.png";
import {useNavigate} from "react-router-dom";
import ClosetIcon from "../../../assets/icons/closet.svg";
import useAuth from "../../../hooks/useAuth";

const ProfileModal = ({user, onClose, getUserProfile}: {
    user: User;
    onClose: () => void;
    getUserProfile?: () => Promise<boolean|undefined>
}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {logout} = useAuth();
    useEffect(() => {
        // Disable scrolling when the modal is open
        document.body.style.overflow = "hidden";

        // Enable scrolling when the modal is closed
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    const handleClickOutside = (event: any) => {
        if (event.target.id === "modal-overlay") {
            onClose();
        }
    };

    const onClickLogoutButton = async () => {
        try {
            onClose();
            let logoutResult = await logout();
            if (logoutResult) {
                navigate('/');
                getUserProfile && getUserProfile();
            }
        } catch (error) {

        }

    }

    return (
        <div
            id="modal-overlay"
            className="fixed inset-0 bg-opacity-0 flex rounded-lg shadow-2xl z-50"
            onClick={handleClickOutside}
        >
            <div className="p-4 top-[8vh] right-[12%] w-72 h-fit fixed shadow-lg bg-white">
                <div className="mt-auto py-3 justify-center flex flex-col items-center">
                    <img
                        src={
                            Math.floor(Math.random() * 101) % 2 === 0
                                ? FemaleProfileBanner
                                : MaleProfileBanner
                        }
                        alt={`{user.name}'s profile`}
                        className="w-2/3 mr-auto rounded-full opacity-20"
                    />
                    <div className="absolute justify-center items-center flex flex-col">
                        <img
                            src={DefaultProfile}
                            alt={`'s profile`}
                            className="w-12 h-12 rounded-full"
                        />
                        <h1 className="text-sm text-gray-700">{`${user?.first_name} ${user?.last_name}`}</h1>
                        <p className="text-xs text-gray-600">@username</p>

                    </div>
                </div>
                <hr/>
                <div className="mt-auto py-3">
                    <SidebarButton
                        text={t("profile")}
                        icon={
                            <img
                                src={ProfileIcon}
                                alt={""}
                                className="w-7 h-7 rounded-full"
                            />
                        }
                        onClick={() => navigate('/profile')}
                    />
                    <SidebarButton
                        text={t("dressing_room")}
                        icon={
                            <img
                                src={ClosetIcon}
                                alt={""}
                                className="w-7 h-7 rounded-full"
                            />
                        }
                        onClick={() => console.log()}
                    />
                </div>
                <hr/>
                <div className="mt-auto py-3">
                    <SidebarButton
                        text={t("sign_out")}
                        icon={
                            <img
                                src={SignOutIcon}
                                alt={""}
                                className="w-7 h-7 rounded-full"
                            />
                        }
                        onClick={() => onClickLogoutButton()}
                        customClass="border"
                    />
                </div>
            </div>
        </div>
    );
};

export default ProfileModal;
