import React from "react";
import {Link, useNavigate} from "react-router-dom";
import Logo from "../../../assets/images/logo/logo.png";
import SidebarButton from "../sidebar-button/SidebarButton";
import KeyIcon from "../../../assets/icons/key.svg";
import OldPenIcon from "../../../assets/icons/old-pen.svg";
import RulerIcon from "../../../assets/icons/ruler.svg";
import SignOutIcon from "../../../assets/icons/sign-out.svg";
import NotificationsIcon from "../../../assets/icons/notification.svg";
import {useTranslation} from "react-i18next";
import useUsers from "../../../hooks/useUsers";
import useAuth from "../../../hooks/useAuth";

const MenuItems = [
    {id: "private_information", icon: OldPenIcon, disabled: false},
    {id: "body_sizes", icon: RulerIcon, disabled: false},
    {id: "change_password", icon: KeyIcon, disabled: false},
    {id: "notifications", icon: NotificationsIcon, disabled: true},
];
const Sidebar = ({
                     selectedMenu,
                     onClickChangeMenu,
                 }: {
    selectedMenu: string;
    onClickChangeMenu: (menu: string) => void;
}) => {
    const {t, i18n} = useTranslation();
    const {logout} = useAuth();
    const navigate = useNavigate();
    const onClickLogout = async () => {
        try{
            let logoutResult = await logout();
            if (logoutResult) {
                navigate('/');
            }
        }catch (error){

        }

    }
    return (
        <div
            className={`h-full w-[20vw] p-10 hidden lg:flex flex-col justify-center ${i18n.language === "fa" ? "border-l" : "border-r"}`}>
            <div className={`flex justify-center `}>
                <Link to="/" className="flex gap-2 items-center mb-3">
                    <img src={Logo} alt={"logo"} className="h-[24px]"/>
                    <h1 className="font-bold text-[2rem] leading-none pt-2">iSketch</h1>
                </Link>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-9">
                {MenuItems?.map((item) => (
                    <SidebarButton
                        key={item.id}
                        text={t(item.id)}
                        icon={
                            <img src={item.icon} alt={""} className="w-7 h-7 rounded-full"/>
                        }
                        onClick={() => onClickChangeMenu(item.id)}
                        selected={item.id === selectedMenu}
                        disabled={item.disabled}
                    />
                ))}
            </div>

            <div className="mt-auto">
                <SidebarButton
                    text={t("sign_out")}
                    icon={
                        <img src={SignOutIcon} alt={""} className="w-7 h-7 rounded-full"/>
                    }
                    onClick={() => onClickLogout()}
                    customClass="border"
                />
            </div>
        </div>
    );
};

export default Sidebar;
