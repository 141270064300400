// src/components/ProtectedRoute.tsx
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

interface ProtectedRouteProps {
  element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { token } = useAuth();

  return token ? element : <Navigate to="/authorization" replace />;
};

export default ProtectedRoute;
