import React from "react";
import Button from "../../atomic/buttons/button/button";
import {useTranslation} from "react-i18next";
import ImageCarousel from "../../composite/image-carousel";
import LandingImage01 from "../../../assets/images/landing-01.png";
import LandingImage02 from "../../../assets/images/landing-02.png";
import LandingImage03 from "../../../assets/images/landing-03.png";
import LandingImage04 from "../../../assets/images/landing-04.png";
import LandingImage05 from "../../../assets/images/landing-05.png";
import LandingImage06 from "../../../assets/images/landing-06.png";
import LandingImage07 from "../../../assets/images/landing-07.png";
import LandingImage08 from "../../../assets/images/landing-08.png";
import LandingImage09 from "../../../assets/images/landing-09.png";
import LandingImage10 from "../../../assets/images/landing-10.png";
import AnimatedText from "../../atomic/text/animated-text";

const Landing = ({targetRef}: { targetRef: any }) => {
    const {t} = useTranslation();

    const handleScroll = () => {
        targetRef?.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    };
    return (
        <div className="flex-col h-[90vh] w-[90vw] justify-center">
            <div className="w-full sm:h-[60%] md:h-[80%] mt-20 md:m-0 bg-white relative">
                <ImageCarousel
                    images={[
                        LandingImage01,
                        LandingImage02,
                        LandingImage03,
                        LandingImage04,
                        LandingImage05,
                        LandingImage06,
                        LandingImage07,
                        LandingImage08,
                        LandingImage09,
                        LandingImage10,
                    ]}
                />
            </div>
            <div className="w-full h-[20%] p-8 text-black text-center grid place-items-center bg-cover')] md:bg-none">
                <div className="grid place-items-center gap-3">
                    <p className="text-sm bg-white">
                        <AnimatedText newText={t("welcome_description")} duration={2}/>
                    </p>
                    <Button
                        variety="secondary"
                        title={t("start")}
                        onClick={() => handleScroll()}
                    />
                </div>
            </div>
        </div>
    );
};

export default Landing;
