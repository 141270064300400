import Guideline from "../../screen/guideline";
import Landing from "../../screen/landing";
import Layout from "../../screen/layout";
import React, { useRef } from "react";

const Home = () => {
  const targetRef = useRef(null);
  return (
    <Layout>
      <>
        <Landing targetRef={targetRef} />
        <Guideline targetRef={targetRef}/>
      </>
    </Layout>
  );
};
export default Home;
