import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import {delay} from "q";

gsap.registerPlugin(TextPlugin);

interface AnimatedTextProps {
    initialText?: string;
    newText: string;
    duration: number;
    delay?: number;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ initialText="", newText, duration, delay=0 }) => {
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textRef.current) {
            gsap.to(textRef.current, {
                duration: duration,
                text: newText,
                ease: 'none',
                delay: delay,
            });
        }
    }, [newText, duration]);

    return (
        <div ref={textRef}>
            {initialText}
        </div>
    );
};

export default AnimatedText;
