import Header from "../../composite/header";
import React from "react";

const Products = () => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-between bg-white">
      <Header />
    </div>
  );
};

export default Products;
