import {useState} from "react";
import {useLazyGetDataQuery, usePutDataMutation} from "../api/services/apiSlice";
import useToast from "./useToast";

export default function useUsers() {
    const [getApi, responseGetApi] = useLazyGetDataQuery();
    const [putApi, responsePutApi] = usePutDataMutation();
    const [userProfile, setUserProfile] = useState<User>();
    const {showToastMessage, showToast} = useToast();

    const updateProfile = async (inputData: UpdateProfileRequestBody) => {
        try {
            let body = {
                first_name: inputData?.firstName,
                last_name: inputData?.lastName,
                initial_update: inputData?.initialUpdate,
            }
            if (inputData?.initialUpdate)
                body = {...body, ...{password: inputData?.password, password_retype: inputData?.retypePassword}};
            if (inputData?.mobileNumber !== '')
                body = {...body, ...{mobile_number: inputData?.mobileNumber}};
            if (inputData?.emailAddress !== '')
                body = {...body, ...{email: inputData?.emailAddress}};
            let response = await putApi({
                url: "users/profile/",
                body: body,
            });
            if (response?.data?.status === 200) {
                showToast(response?.data?.message, 'success')
                return true;
            } else {
                showToastMessage(response);
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getUserProfile = async () => {
        try {
            let response = await getApi({
                url: "users/profile/",
            });
            if (response?.isSuccess) {
                setUserProfile(response?.data?.data?.member);
                return true;
            } else {
                setUserProfile(undefined);
                // showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            // dispatch(setDisplayModal(false));
        }
    };

    const changePassword = async (inputData: ChangePasswordRequestBody) => {
        try {
            let response = await putApi({
                url: "users/change-password",
                body: inputData,
            });
            if (response?.data?.status === 200) {
                showToast(response?.data?.message, 'success')
                return true;
            } else {
                showToastMessage(response);
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    // useEffect(() => {
    //   if (responsePostApi?.isSuccess) {
    //     if (responsePostApi?.originalArgs?.url === "users/login/") {
    //     } else if (responsePostApi?.originalArgs?.url === "users/register/") {
    //       dispatch(
    //         setModalData({
    //           emailAddressOrPhoneNumber:
    //             responsePostApi?.originalArgs?.body?.email,
    //           token: responsePostApi?.data?.data?.token,
    //         })
    //       );
    //       dispatch(setModalType("verify-register-by-otp"));
    //       dispatch(setDisplayModal(true));
    //     }
    //     responsePostApi?.reset();
    //   }
    // }, [responsePostApi, dispatch]);

    return {
        getUserProfile,
        userProfile,
        updateProfile,
        responsePutApi,
        responseGetApi,
        changePassword,
    };
}
