import React from "react";
import { useTranslation } from "react-i18next";
import NotificationsImage from "../../../assets/images/notifications-banner.png";
import NotificationCard from "../../composite/notification-card";

const Notifications = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`p-6 ${i18n.language === "fa" ? "text-right" : "text-left"}`}
    >
      <h1 className="font-bold text-lg">{t("notifications")}</h1>
      <p className="text-sm">{t("notifications_description")}</p>
      <div className="grid grid-cols-4 mt-8 h-[75vh]">
        <div className="grid col-span-1">
          <img src={NotificationsImage} alt={"model"} className="mt-auto" />
        </div>
        <div className="grid col-span-3 gap-3 w-full h-fit items-start justify-arrownd">
          <NotificationCard type={"danger"} />
          <NotificationCard type={"warning"}/>
          <NotificationCard type={"success"}/>
          <NotificationCard type={"info"}/>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
