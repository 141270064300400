import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';

const useToast = () => {
  const showToast = (message: string, type = "default") => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "warning":
        toast.warn(message);
        break;
      default:
        toast(message);
    }
  };

  interface ErrorData {
    message: string;
  }

  // Type guard to check if error data has the expected structure
  function hasMessageProperty(data: any): data is ErrorData {
    return typeof data === "object" && data !== null && "message" in data;
  }

  // Type guard to check if error is of type FetchBaseQueryError
  function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
    return typeof error === "object" && error !== null && "data" in error;
  }

  const showToastMessage = (response: {
    error?: FetchBaseQueryError | SerializedError;
  }) => {
    if (response.error) {
      if (
        isFetchBaseQueryError(response.error) &&
        hasMessageProperty(response.error.data)
      ) {
        showToast(response.error.data.message, "error");
      } else {
        showToast("An unexpected error occurred", "error");
      }
    } else {
      showToast("An unexpected error occurred", "error");
    }
  };

  return {showToast, showToastMessage};
};

export default useToast;
