import React, {KeyboardEvent, useState} from "react";
import Input from "../../../../atomic/input";
import Button from "../../../../atomic/buttons/button";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import {isEmailOrIranianPhoneNumber} from "../../../../../utils/validators";
import {setDisplayModal, setModalType} from "../../../../../redux/slices/displayModalsSlice";
import {useDispatch} from "react-redux";
import useToast from "../../../../../hooks/useToast";

const RegisterSection = () => {
    const {t} = useTranslation();
    const [emailAddressOrPhoneNumber, setEmailAddressOrPhoneNumber] = useState("");
    const {register} = useAuth();
    const dispatch = useDispatch();
    const { showToast } = useToast();

    const onClickSignUpBtn = async () => {
        try {
            if (emailAddressOrPhoneNumber !== "") {
                if (isEmailOrIranianPhoneNumber(emailAddressOrPhoneNumber).result) {
                    let response = await register(emailAddressOrPhoneNumber);
                    if (response) {
                        dispatch(setDisplayModal(true));
                        dispatch(setModalType('verify-register-by-otp'));
                    }
                } else {
                    showToast(t("email_address_or_phone_number_not_valid"), "error")
                }
            } else {
                showToast(t("fill_inputs"), "error")
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="grid gap-4 px-6">
            <Input
                onChange={(e) => setEmailAddressOrPhoneNumber(e.target.value)}
                value={emailAddressOrPhoneNumber}
                placeholder={t("emailAddressOrPhoneNumber")}
                autoComplete="email"
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        onClickSignUpBtn();
                    }
                }}
            />
            <Button customClassName="w-full" disabled={emailAddressOrPhoneNumber === ""} title={t("sign_up")} onClick={() => onClickSignUpBtn()}/>
        </div>
    );
};

export default RegisterSection;
