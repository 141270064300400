import React from 'react';
import {useTranslation} from "react-i18next";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const BodyPartChip = ({percentage, label, description, onClickHandle}: {
    percentage: number;
    label: string;
    description: string;
    onClickHandle: () => void;
}) => {
    const {i18n} = useTranslation()

    return (
        <button
            className={`flex justify-center items-center w-full shadow-md rounded-lg leading-[0px] text-[.8rem] p-2 mt-5 transition duration-300 gap-2`}
            style={{
                direction: i18n.language === 'fa' ? 'rtl' : 'ltr',
            }}
            onClick={() => onClickHandle()}
        >
            <div style={{width: 50, height: 50}}>
                <CircularProgressbar
                    className={'items-center flex'}
                    strokeWidth={12}
                    styles={buildStyles(
                        {
                            pathColor: '#222',
                            textColor: '#123'
                        })}
                    value={percentage}
                    text={`${percentage} %`}
                />
            </div>
            <div className={'px-2'}>
                <p className={'text-xs font-bold text-start'}>{label}</p>
                <p className={'text-[10px] leading-3 mt-1 text-gray-500 text-start'}>{description}</p>
            </div>
        </button>
    )
        ;
};

export default BodyPartChip;
