import React, { useState } from "react";
import Sidebar from "../../composite/sidebar/Sidebar";
import SidebarLayout from "../../screen/sidebar-layout";
import ProfileHeader from "../../composite/profile-header";
import BodySizes from "../../screen/body-sizes";
import ChangePassword from "../../screen/change-password/ChangePassword";
import PrivateInformation from "../../screen/private-information";
import Notifications from "../../screen/notifications";

const Profile = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>("private_information");
  const giveMeTheContent = () => {
    try {
      switch (selectedMenu) {
        case "private_information":
          return <PrivateInformation />;
        case "change_password":
          return <ChangePassword />;
        case "body_sizes":
            return <BodySizes />
        case "notifications":
          return <Notifications />
        default:
          return <h1>MAIN Content</h1>;
      }
    } catch (error) {
      return <></>;
    }
  };

  return (
    <SidebarLayout sidebar={<Sidebar selectedMenu={selectedMenu} onClickChangeMenu={setSelectedMenu} />}>
      <>
        <ProfileHeader />
        {giveMeTheContent()}
      </>
    </SidebarLayout>
  );
};

export default Profile;
