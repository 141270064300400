import React from "react";
import Layout from "../../screen/layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <p>privacy policy</p>
    </Layout>
  );
};

export default PrivacyPolicy;
