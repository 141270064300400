import React, {useState} from "react";
import DownArrowIcon from "../../../assets/icons/down-arrow.svg";
import DefaultProfile from "../../../assets/icons/default-profile.svg";
import IconButton from "../../atomic/buttons/icon-button";
import {createPortal} from "react-dom";
import ProfileModal from "../profile-modal/ProfileModal";

const ProfileCard = ({
                         user,
                         hasMenu = true,
                         getUserProfile,
                     }: {
    user: User;
    hasMenu?: boolean;
    getUserProfile?: () => Promise<boolean|undefined>;
}) => {
    const [showModal, setShowModal] = useState<boolean>();

    return (
        <div className="flex items-center gap-2 rounded-lg">
            <img
                src={
                    DefaultProfile //user?.imageURL ?? DefaultProfile
                }
                alt={`${user.first_name}'s profile`}
                className="w-8 h-8 rounded-full"
            />
            <h3 className="text-xs">{user.first_name}</h3>
            {hasMenu && (
                <IconButton
                    icon={
                        <img
                            src={DownArrowIcon}
                            alt={"arrow down"}
                            className="w-4 h-4 rounded-full"
                        />
                    }
                    onClick={() => {
                        setShowModal(true);
                    }}
                    customClass="!p-1 min-w-0"
                />
            )}
            {showModal &&
                createPortal(
                    <ProfileModal user={user} onClose={() => setShowModal(false)} getUserProfile={getUserProfile}/>,
                    document.body
                )}
        </div>
    );
};

export default ProfileCard;
