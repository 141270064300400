import React from "react";
import { useTranslation } from "react-i18next";

const SidebarLayout = ({
  children,
  sidebar,
}: {
  children: JSX.Element;
  sidebar: JSX.Element;
}) => {
  const {i18n} = useTranslation();

  return (
    <div className={`flex h-[100vh] bg-white ${i18n.language === "fa"  ? "flex-row-reverse" : "flex-row"}`}>
      {sidebar}
      <main className="flex-grow p-4 w-[80vw]">{children}</main>
    </div>
  );
};

export default SidebarLayout;
