import React, {
    useState,
    KeyboardEvent
} from "react";
import {CSSTransition} from "react-transition-group";
import Input from "../../../../atomic/input";
import Button from "../../../../atomic/buttons/button";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {isEmailOrIranianPhoneNumber} from "../../../../../utils/validators";
import useToast from "../../../../../hooks/useToast";

const LoginSection = () => {
    const {t, i18n} = useTranslation();
    const [loginRequestBody, setLoginRequestBody] = useState<LoginRequestBody>({
        emailAddressOrPhoneNumber: '',
        password: ''
    });
    const [emailAddressOrPhoneNumber, setEmailAddressOrPhoneNumber] = useState("");

    const [displayAuthSection, setDisplayAuthSection] = useState<boolean>(true);
    const [displayRecoverySection, setDisplayRecoverySection] =
        useState<boolean>(false);
    const {login, resetPasswordRequest} = useAuth();
    const {showToast} = useToast();

    const navigate = useNavigate();
    const onClickSignInBtn = async () => {
        try {
            if (loginRequestBody?.emailAddressOrPhoneNumber !== "" && loginRequestBody?.password !== "") {
                if (isEmailOrIranianPhoneNumber(loginRequestBody?.emailAddressOrPhoneNumber).result) {
                    let isLoggedIn = await login(loginRequestBody);
                    if (isLoggedIn) {
                        navigate("/");
                    }
                } else {
                    showToast(t("email_address_or_phone_number_not_valid"), "error")
                }
            } else {
                showToast(t("fill_inputs"), "error")
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClickResetPasswordRequestButton = async () => {
        try {
            if (emailAddressOrPhoneNumber !== "") {
                if (isEmailOrIranianPhoneNumber(emailAddressOrPhoneNumber).result) {
                    let isLoggedIn = await resetPasswordRequest(emailAddressOrPhoneNumber);
                    if (isLoggedIn) {
                        navigate("/");
                    }
                } else {
                    showToast(t("email_address_or_phone_number_not_valid"), "error")
                }
            } else {
                showToast(t("fill_inputs"), "error")
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        try {
            setLoginRequestBody(
                loginRequestBody && {...loginRequestBody, [name]: value}
            );
        } catch (error) {
        }
    };

    return (
        <>
            <CSSTransition
                in={displayAuthSection}
                timeout={300}
                classNames="fade"
                unmountOnExit
                onExited={() => setDisplayRecoverySection(true)}
            >
                <div className="grid gap-4 px-6">
                    <Input
                        onChange={(e) => onChangeInput(e)}
                        value={loginRequestBody?.emailAddressOrPhoneNumber ?? ''}
                        placeholder={t("emailAddressOrPhoneNumber")}
                        autoComplete="email"
                        name={"emailAddressOrPhoneNumber"}
                        type="email"
                    />
                    <Input
                        onChange={(e) => onChangeInput(e)}
                        value={loginRequestBody?.password ?? ''}
                        placeholder={t("password")}
                        type="password"
                        name={"password"}
                        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                                onClickSignInBtn();
                            }
                        }}
                    />
                    <Button
                        customClassName="w-full"
                        title={t("sign_in")}
                        onClick={() => onClickSignInBtn()}
                        disabled={loginRequestBody?.password === '' || loginRequestBody?.emailAddressOrPhoneNumber === ''}
                    />
                    <Button
                        onClick={() => setDisplayAuthSection(false)}
                        title={t("recovery_password")}
                        variety="link"
                        customClassName={`mx-2 ${
                            i18n.language === "fa" ? "justify-self-end" : "justify-self-start"
                        }`}
                    />
                </div>
            </CSSTransition>

            <CSSTransition
                in={displayRecoverySection}
                timeout={300}
                classNames="fade"
                unmountOnExit
                onExited={() => setDisplayAuthSection(true)}
            >
                <div className="grid gap-4 px-6">
                    <Input
                        onChange={(e) => setEmailAddressOrPhoneNumber(e.target.value)}
                        value={emailAddressOrPhoneNumber}
                        placeholder={t("emailAddressOrPhoneNumber")}
                    />
                    <Button customClassName="w-full" disabled={emailAddressOrPhoneNumber === ""}
                            title={t("send_request")} onClick={() => onClickResetPasswordRequestButton()}/>
                    <Button
                        onClick={() => setDisplayRecoverySection(false)}
                        title={t("back")}
                        variety="link"
                        customClassName="mx-auto my-3 w-full"
                    />
                </div>
            </CSSTransition>
        </>
    );
};

export default LoginSection;
