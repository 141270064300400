import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DisplayModalsState {
  displayModal: boolean;
  modalType: "loading" | "verify-register-by-otp" | "complete-profile" | "guideline" | null;
  data: verificationModalDataObject | guidelineModalDataObject | {};
}

const initialState: DisplayModalsState = {
  displayModal: false,
  modalType: null,
  data: {},
};

const displayModalsSlice = createSlice({
  name: "modal management",
  initialState,
  reducers: {
    setDisplayModal: (state, action: PayloadAction<boolean>) => {
      state.displayModal = action.payload;
    },
    setModalType: (
      state,
      action: PayloadAction<
        "loading" | "verify-register-by-otp" | "complete-profile" | "guideline" | null
      >
    ) => {
      state.modalType = action.payload;
    },
    setModalData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
  },
});

export const { setModalData, setModalType, setDisplayModal } =
  displayModalsSlice.actions;

export default displayModalsSlice.reducer;
