import React, { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ options, name, onChange, className, value }) => {
  const [selectedValue, setSelectedValue] = useState<string>(value ? value :'');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const {i18n} = useTranslation();

  return (
    <div className={`flex ${className}`}>
      {options.map((option) => (
        <label key={option.value} className="text-xs" style={{direction: i18n?.language === 'fa' ? 'rtl' : 'ltr'}}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            className="mx-2"
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
