import React from "react";
import Header from "../../composite/header";
import Footer from "../../composite/footer";

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-between bg-white">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
