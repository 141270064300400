import React from "react";
import {useTranslation} from "react-i18next";
import Button from "../../../atomic/buttons/button";
import {useDispatch, useSelector} from "react-redux";
import {setDisplayModal} from "../../../../redux/slices/displayModalsSlice";
import {RootState} from "../../../../redux/store";
import FadeInText from "../../../atomic/text/fade-in-text";

const GuidelineModal = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const modalsManagementData = useSelector((state: RootState) => state.modalsManagement?.data)
    return (
        <FadeInText>
        <div className="flex items-center justify-center flex-col gap-y-8 bg-white rounded-3xl shadow-lg p-10">
            <div className="text-center text-gray-500 text-sm"
                 style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}>
                <p className="text-md text-black font-bold text-center m-0">
                    {(modalsManagementData as guidelineModalDataObject)?.title}
                </p>
                <p className="m-0 mb-5 mt-2">{(modalsManagementData as guidelineModalDataObject)?.description}</p>
                {
                    (modalsManagementData as guidelineModalDataObject)?.tips.map(
                        tip =>
                            <li className={'text-start text-xs my-2'}>
                                <p className={'font-bold text-end inline'}>
                                    {tip?.title}
                                </p>: {tip?.description}
                            </li>
                    )
                }

            </div>
            <Button title={t("ok")} onClick={() => dispatch(setDisplayModal(false))}/>
        </div>
        </FadeInText>
    );
};

export default GuidelineModal;
