import React, {useEffect} from "react";
import {I18nextProvider, useTranslation} from "react-i18next";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Products from "./components/pages/products";
import Authorization from "./components/pages/authorization";
import Home from "./components/pages/home";
import DressingRoom from "./components/pages/dressing-room";
import TermsOfUse from "./components/pages/terms-of-use";
import PrivacyPolicy from "./components/pages/privacy-policy";
import Profile from "./components/pages/profile";
import ProtectedRoute from "./components/routes/protected-route";
import PublicRoute from "./components/routes/public-route";
import ModalLayout from "./components/screen/modal-layout";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from "./i18n";
import NotFound from "./components/pages/not-found";
import Shops from "./components/pages/shops";

const App = () => {
    const {i18n : I18N} = useTranslation();

    useEffect(() => {
        const htmlElement = document.documentElement;

        if (I18N.language === 'fa') {
            htmlElement.style.fontFamily = "'vazir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
        } else {
            htmlElement.style.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
        }
    }, [I18N.language]);

    return (
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <ModalLayout/>
                <ToastContainer/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/authorization" element={<PublicRoute element={<Authorization/>}/>}/>
                    <Route path="/authorization/reset-password/:token/:pin?"
                           element={<PublicRoute element={<Authorization resetPassword={true}/>}/>}/>
                    <Route path="/products" element={<Products/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                    <Route path="/about-us" element={<TermsOfUse/>}/>
                    <Route path="/contact" element={<TermsOfUse/>}/>
                    <Route path="/shops" element={<ProtectedRoute element={<Shops/>}/>}/>
                    <Route path="/dressing-room" element={<ProtectedRoute element={<DressingRoom/>}/>}/>
                    <Route path="/profile" element={<ProtectedRoute element={<Profile/>}/>}/>
                    <Route path="/404" element={<NotFound />}/>

                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            </I18nextProvider>
        </BrowserRouter>
    );
};

export default App;
