import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo/logo.png";

const Footer = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="grid w-full bg-[#f5f5f5] text-black px-10 md:px-20 lg:px-40 py-10 gap-3 items-center">
      <hr />
      <div className="grid grid-cols-1 md:grid-cols-5 gap-3 w-full">
        <Link to="/" className="flex gap-2 items-center">
          <img src={Logo} alt={"logo"} className="h-[24px]" />
          <h1 className="font-bold text-[1.4rem] leading-none pt-2">iSketch</h1>
        </Link>
        <div className="grid md:col-span-4 grid-cols-2 md:grid-cols-4 text-sm" style={{ direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Link to="/about-us" className="flex gap-2 items-center">
            {t("about_us")}
          </Link>
          <Link to="/contact" className="flex gap-2 items-center">
            {t("contact_us")}
          </Link>
        </div>
      </div>
      <hr />
      <div
        className="grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-0 items-end justify-between text-xs"
        style={{ direction: i18n.language === "fa" ? "rtl" : "ltr" }}
      >
        <p className="text-gray-500">{t("copyright")}</p>
        <div className="flex justify-center">
          <Link to="/privacy-policy" className="flex gap-2 items-center">
            {t("privacy_policy")}
          </Link>
          <div className="mx-2 border-l-[1px] border-gray-500 h-[20px]" />
          <Link to="/terms-of-use" className="flex gap-2 items-center">
            {t("terms_of_use")}
          </Link>
        </div>
        <p className="flex gap-1 justify-center md:justify-end">{t("location")}</p>
      </div>
    </div>
  );
};
export default Footer;
