import React, {ReactNode, useEffect, useRef} from 'react';
import {gsap} from 'gsap';

const FadeInText = ({delay = 0, children}: { delay?: number; children: ReactNode }) => {
    const textRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            textRef.current,
            {
                opacity: 0,
                y: 20,
            },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: 'power2.out',
                delay: delay,
            }
        );
    }, []);

    return <div ref={textRef}>{children}</div>;
};

export default FadeInText;
