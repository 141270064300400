import React from "react";
import './styles.css';
import {useTranslation} from "react-i18next";

const Button = ({
  title,
  variety = "primary",
  icon,
  onClick,
  type = "button",
  disabled = false,
  customClassName,
  loading = false,
}: {
  title?: string;
  variety?: "primary" | "secondary" | "tertiary" | "dashed" | "link";
  icon?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  customClassName?: string;
  loading?: boolean;
}) => {
  const {i18n} = useTranslation();
  let classNameForVariety =
    variety === "primary"
      ? `text-white hover:bg-gray ${disabled ? 'bg-gray-400' : 'bg-black'}`
      : variety === "secondary"
      ? `bg-white border-[1px] hover:bg-black hover:text-white ${disabled ? 'border-gray-400 text-gray-400' : 'border-black text-black'}`
      : variety === "tertiary"
      ? "bg-gray-100 hover:bg-gray-200"
      : variety === "dashed"
      ? `bg-white border-[1px] border-dashed hover:bg-black hover:text-white hover:border-white ${disabled ? 'border-gray-400 text-gray-400' : 'border-black text-black'}`
      : variety === "link"
      ? "bg-transparent hover:underline !p-0 !text-[.7rem]"
      : "";
  return (
      <button
      type={type}
      disabled={disabled}
      className={`flex justify-center items-center rounded-full leading-[0px] text-[.8rem] w-fit min-w-[84px] p-4 max-h-fit transition duration-300 gap-2 ${classNameForVariety} ${customClassName}`}
      onClick={onClick}
      style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}
    >
      {loading ? (
        <span className={`button_loader`}></span>
      ) : (
        <>
          {icon}
          {title}
        </>
      )}
    </button>
  );
};

export default Button;
