import React, {
    ChangeEventHandler,
    forwardRef,
    KeyboardEventHandler, useState,
} from "react";
import {useTranslation} from "react-i18next";
import CloseEyeIcon from '../../../assets/icons/close-eye.svg';
import OpenEyeIcon from '../../../assets/icons/open-eye.svg';
import IconButton from "../buttons/icon-button";
import {CSSTransition} from "react-transition-group";

interface InputProps {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    label?: string;
    name?: string;
    type?: "default" | "password" | "email";
    icon?: any;
    placeholder?: string;
    hint?: string;
    maxLength?: number;
    className?: string;
    required?: boolean;
    autoComplete?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            onChange,
            onKeyDown,
            label,
            name,
            type = "default",
            icon,
            placeholder,
            hint,
            maxLength,
            className,
            required = false,
            autoComplete,
        },
        ref
    ) => {
        const {i18n, t} = useTranslation();
        const [displayPasswordOption, setDisplayPasswordOption] = useState(true);
        const [hidePasswordOption, setHidePasswordOption] = useState(false);

        return (
            <div
                className={`grid grid-cols-1 gap-1 justify-start ${
                    i18n.language === "fa" ? "text-right" : "text-left"
                }`}
            >
                {icon}
                <div className="flex" style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}>
                    {label && <p className="px-3 text-xs">{label}</p>}
                    {required && (
                        <p className={`px-3 text-[10px] text-purple-500 ${i18n.language === 'fa' ? 'mr-auto' : 'ml-auto'}`}>{t("required")}</p>
                    )}
                </div>
                {hint && <p className="px-3 text-[.6rem] text-gray-500">{hint}</p>}
                <input
                    ref={ref}
                    name={name}
                    className={`py-2 px-6 rounded-full text-black focus:outline-none focus:border-[1px] focus:border-gray-300 bg-[#f5f5f5] placeholder:text-sm ${
                        i18n.language === "fa" ? "text-right" : "text-left"
                    } ${className}`}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    type={displayPasswordOption ? type : 'text'}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    autoComplete={autoComplete}
                />
                {(type === 'password' && value !=='') &&
                    <><CSSTransition
                        in={displayPasswordOption}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                        onExited={() => setHidePasswordOption(true)}
                    >
                        <IconButton
                            withHover={false}
                            customClass={'absolute mt-2'}
                            onClick={() => setDisplayPasswordOption(false)}
                            icon={<img src={OpenEyeIcon} alt={'EyeIcon'}/>}
                        />
                    </CSSTransition>
                        <CSSTransition
                            in={hidePasswordOption}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                            onExited={() => setDisplayPasswordOption(true)}
                        >
                            <IconButton
                                withHover={false}
                                customClass={'absolute mt-2'}
                                onClick={() => setHidePasswordOption(false)}
                                icon={<img src={CloseEyeIcon} alt={'EyeIcon'}/>}
                            />
                        </CSSTransition></>
                }
            </div>
        );
    }
);

export default Input;
