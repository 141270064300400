import React, {useEffect, useRef} from 'react';
import {gsap} from 'gsap';

interface FadeLayoutProps {
    visible: boolean;
    children: React.ReactNode;
    delay?: number;
    duration?: number;
}

const FadeLayout: React.FC<FadeLayoutProps> = ({visible, children, delay = 0, duration = 1}) => {
    const layoutRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (visible) {
            gsap.fromTo(
                layoutRef.current,
                {
                    opacity: 0,
                    display: 'none',
                },
                {
                    opacity: 1,
                    display: 'flex',
                    duration: duration,
                    ease: 'power2.out',
                    delay: delay,
                }
            );
        } else {
            gsap.to(layoutRef.current, {
                opacity: 0,
                display: 'none',
                duration: duration,
                ease: 'power2.out',
                delay: delay,
            });
        }
    }, [visible, delay]);

    return <div ref={layoutRef}>{children}</div>;
};

export default FadeLayout;
