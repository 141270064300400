import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../atomic/input';
import ChangePasswordImage from '../../../assets/images/change-password-banner.png'
import Button from '../../atomic/buttons/button';
import useUsers from "../../../hooks/useUsers";
import useToast from "../../../hooks/useToast";
import FadeInText from "../../atomic/text/fade-in-text";

const ChangePassword = () => {
    const {t, i18n} = useTranslation();
    const {changePassword} = useUsers();
    const {showToast} = useToast();

    const [changePasswordRequestBody, setChangePasswordRequestBody] = useState<ChangePasswordRequestBody>({
        current_password: '',
        new_password: '',
        retype_new_password: '',
    });
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        try {
            setChangePasswordRequestBody(
                changePasswordRequestBody && {...changePasswordRequestBody, [name]: value}
            );
        } catch (error) {
        }
    };
    const onClickChangePasswordButton = async () => {
        try {
            if (changePasswordRequestBody?.new_password === changePasswordRequestBody?.retype_new_password) {
                let result = await changePassword(changePasswordRequestBody);
                if (result) {
                    setChangePasswordRequestBody({
                        current_password: '',
                        new_password: '',
                        retype_new_password: '',
                    })
                }
            } else {
                showToast(t('passwords_not_match'))
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (<div
        className={`p-6 ${i18n.language === "fa" ? "text-right" : "text-left"}`}
        style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}}
    >
        <h1 className="font-bold text-lg">{t("change_password")}</h1>
        <FadeInText>
            <p className="text-sm">{t("change_password_description")}</p>
        </FadeInText>
        <div className="grid grid-cols-4 mt-8 h-[75vh]" style={{direction: i18n.language === 'en' ? 'rtl' : 'ltr'}}>
            <div className="grid col-span-1 h-full">
                <img src={ChangePasswordImage} alt={"model"} className="mt-auto"/>
            </div>
            <div className="grid col-span-1 col-start-3 gap-3 w-full h-fit">
                <FadeInText delay={.1}>
                    <Input
                        value={changePasswordRequestBody?.current_password}
                        onChange={(e) => onChangeInput(e)}
                        label={t("current_password")}
                        type={'password'}
                        name={'current_password'}
                    />
                </FadeInText>
                <FadeInText delay={.2}>
                    <Input
                        value={changePasswordRequestBody?.new_password}
                        onChange={(e) => onChangeInput(e)}
                        label={t("new_password")}
                        hint={t("password_hint")}
                        type={'password'}
                        name={'new_password'}
                    />
                </FadeInText>
                <FadeInText delay={.3}>
                    <Input
                        value={changePasswordRequestBody?.retype_new_password}
                        onChange={(e) => onChangeInput(e)}
                        label={t("retype_new_password")}
                        type={'password'}
                        name={'retype_new_password'}
                    />
                </FadeInText>
                <FadeInText delay={.4}>
                    <Button
                        onClick={() => onClickChangePasswordButton()}
                        disabled={changePasswordRequestBody?.current_password === '' || changePasswordRequestBody?.new_password === '' || changePasswordRequestBody?.retype_new_password === ''}
                        title={t("change_password")} customClassName='w-full'/>
                </FadeInText>
            </div>
        </div>
    </div>);
}

export default ChangePassword;
