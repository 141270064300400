import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

const LoadingModal = () => {
  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-75 flex items-center justify-center z-50">
      <div className="flex items-center justify-center flex-col gap-y-8">
        <span className="loader"></span>
        <p className="text-sm text-gray-500 font-bold text-center m-0">{t("waiting")}</p>
      </div>
    </div>
  );
};

export default LoadingModal;
