import React, { ChangeEventHandler } from "react";
// import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/icons/search.svg";

const SearchInput = ({
  value,
  onChange,
  label,
  name,
  type = "default",
  icon,
  placeholder,
}: {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string;
  type?: "default" | "password"
  icon?: any;
  placeholder?: string;
}) => {
    // const { i18n } = useTranslation();

  return (
    <div className={`relative grid grid-cols-1 gap-1 items-center text-center`}>
      <img src={SearchIcon} alt={"search icon"} className="absolute left-1" />
      {label && <p className="px-3 text-xs">{label}</p>}
      <input
        name={name}
        className={`py-1 px-4 border rounded-full text-black placeholder:text-sm text-center`}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
