export const baseHeaders = (headers: any) => {
  const tokenString = localStorage.getItem('token');
  let access_token = null;

  if (tokenString) {
    try {
      const parsedToken = JSON.parse(tokenString);
      access_token = parsedToken?.access ?? null;
    } catch (error) {
      console.error('Error parsing token:', error);
    }
  }

  access_token && headers.set('Authorization', `Bearer ${access_token}`);
  return headers;
};