import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.css";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import "./styles/image-carousel.css";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";

const rootElement = document.getElementById("root");
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
        <Provider store={store}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
