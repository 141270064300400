import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { baseHeaders } from "../headers";

/**
 * Base query function that handles token refresh.
 */
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: baseHeaders,
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // try to get a new token
        const token = localStorage.getItem('token');
        if (token) {
            const { refresh } = JSON.parse(token);
            const refreshResult = await baseQuery({
                url: '/api/token/refresh/',
                method: 'POST',
                body: { refresh },
            }, api, extraOptions);

            if (refreshResult.data) {
                const { access } = refreshResult.data as { access: string };
                // store the new token
                localStorage.setItem('token', JSON.stringify({ access, refresh }));
                // retry the original query with new token
                result = await baseQuery(args, api, extraOptions);

                // If retrying also results in 401, remove the token
                if (result.error && result.error.status === 401) {
                    localStorage.removeItem('token');
                }
            } else {
                localStorage.removeItem('token');
            }
        } else {
            localStorage.removeItem('token');
        }
    }

    return result;
};

export default baseQueryWithReAuth;
