import React from "react";
import Tooltip from "../../tooltip";

const IconButton = ({
                        title,
                        icon,
                        onClick,
                        customClass = "",
                        withHover = true,
                        disabled = false,
                        tipContent,
                    }: {
    title?: string;
    icon?: any;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    customClass?: string;
    withHover?: boolean;
    disabled?: boolean;
    tipContent?: string;
}) => {
    return tipContent && !disabled ?
        (
            <Tooltip text={tipContent} position={'bottom'}>
                <button
                    className={`rounded-full text-[.8rem] w-fit p-1 transition duration-300 min-w-8 text-lg ${(withHover && !disabled) && 'hover:bg-slate-200'} items-center justify-center flex px-2 ${customClass}`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {icon}
                    {title && <p className={'text-xs mx-1'}>{title}</p>}
                </button>
            </Tooltip>
        ) :
        (
            <button
                className={`rounded-full text-[.8rem] w-fit p-1 transition duration-300 min-w-8 text-lg ${disabled && 'opacity-50'} ${(withHover && !disabled) && 'hover:bg-slate-200'} items-center justify-center flex px-2 ${customClass}`}
                onClick={onClick}
                disabled={disabled}
            >
                {icon}
                {title && <p className={'text-xs mx-1'}>{title}</p>}
            </button>
        )

};

export default IconButton;
