import React, { useEffect } from "react";
import IconButton from "../../atomic/buttons/icon-button";
import { useTranslation } from "react-i18next";
import ProfileCard from "../profile-card/ProfileCard";
import GlobeIcon from "../../../assets/icons/globe.svg";
import SearchInput from "../../atomic/SearchInput";
import useUsers from "../../../hooks/useUsers";

const ProfileHeader = () => {
  const { i18n } = useTranslation();
  const { getUserProfile, userProfile } = useUsers();
  const onClickChangeLanguageButton = () => {
    try {
      let currentLanguage = i18n.language;
      i18n.changeLanguage(currentLanguage === "fa" ? "en" : "fa");
    } catch (error) {}
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="h-[5vh] w-full bg-white text-black flex justify-around gap-3 items-center border-b pb-4">
      <SearchInput value={""} onChange={() => {}} />
      <div className="flex gap-3">
        {userProfile && <ProfileCard user={userProfile} hasMenu={false} />}
        <IconButton
            title={i18n.language === "en" ? "En" : "فا"}
            icon={
            <img
              src={GlobeIcon}
              alt={"arrow down"}
              className="w-5 h-5 rounded-full"
            />
          }
          onClick={() => {
            onClickChangeLanguageButton();
          }}
        />
      </div>
    </div>
  );
};

export default ProfileHeader;
