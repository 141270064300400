import { useTranslation } from "react-i18next";
import Layout from "../../screen/layout";
import React from "react";
import Title from "../../atomic/title";
// import Input from "../../atomic/input/input";
// import Button from "../../atomic/button";

const Shops = () => {
  const { t } = useTranslation();
  // const [inputs, setInputs] = useState({ query: "" });

  // const handleChange = (event: any) => {
  //   try {
  //     const name = event.target.name;
  //     const value = event.target.value;
  //     setInputs((values) => ({ ...values, [name]: value }));
  //   } catch (error) {
  //     console.error(error)
  //   }
  // };

  // const onClickSubmit = (event: any) => {
  //   try {
  //     event.preventDefault();
  //     // send the request
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <Layout>
      <>
        <Title context={t("shops")} />
        <p>{t("dressing_room")}</p>
        <p></p>
      </>
    </Layout>
  );
};

export default Shops;
