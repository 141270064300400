import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import './styles.css';
import IconButton from "../../atomic/buttons/icon-button";
import LeftArrowIcon from '../../../assets/icons/left-arrow.svg';
import RightArrowIcon from '../../../assets/icons/right-arrow.svg';
import MeterInput from "../meter-input";
import {useTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const BodySizeSlider = (
    {
        items,
        selectedBodyRegion,
        updateUserBodyMeasurements
    }: {
        items: BodyMeasurementItem[],
        selectedBodyRegion: { name: string },
        updateUserBodyMeasurements: (inputData: UpdateUserBodyMeasurementsRequestBody) => Promise<boolean>
    }) => {
    const {i18n, t} = useTranslation()
    const [selectedItem, setSelectedItem] = useState<BodyMeasurementItem | undefined>();

    const onClickChangeSliderButton = (type: 'previous' | 'next') => {
        try {
            const currentIndex = items?.findIndex(item => item === selectedItem);
            let newIndex = 0;
            if (!items)
                return

            if (type === 'previous') {
                newIndex = (currentIndex - 1 + items?.length) % items?.length;
            } else {
                newIndex = (currentIndex + 1) % items?.length;
            }
            setSelectedItem(items[newIndex]);
        } catch (error) {
            console.error(error);
        }
    }

    const onClickUpdateBodyPartMeasurementButton = async (inputValue: number) => {
        try {
            let data: UpdateUserBodyMeasurementsRequestBody =
                [
                    {
                        size_number: inputValue,
                        size_unit: 'CM',
                        body_part: selectedItem?.name ?? '',
                        body_region: selectedBodyRegion.name
                    }
                ]
            ;
            return await updateUserBodyMeasurements(data);
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    useEffect(() => {
        setSelectedItem(
            items[0]
        )
    }, [items]);

    return (
        <div className="grid grid-cols-7 px-20 gap-2 justify-center min-w-[28%]">
            <div className={'col-span-1 flex justify-center m-auto'}>
                <IconButton
                    onClick={() => onClickChangeSliderButton('previous')}
                    icon={<img src={LeftArrowIcon} alt={'prev'}/>}
                />
            </div>
            {
                selectedItem && <div
                    className={'col-span-5 flex flex-col justify-center items-center w-full rounded-2xl p-10 my-10'}>
                    <p className={'flex gap-1'} style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}}>
                        {t('update')}
                        <p className={'font-bold'}>
                            {i18n.language === 'en' ? selectedItem.en_title : selectedItem.fa_title}
                        </p>
                    </p>
                    <p
                        className={'text-xs text-gray-500'}
                    >
                        {i18n.language === 'en' ? selectedItem?.en_description : selectedItem?.fa_description}
                    </p>
                    <img
                        className={'w-40 h-40 rounded my-2'}
                        src={selectedItem.image_url}
                        alt={`${selectedItem.en_title}-img`}
                    />
                    <MeterInput
                        value={selectedItem.value}
                        onChange={onClickUpdateBodyPartMeasurementButton}
                    />
                </div>
            }
            <div className={'col-span-1 flex justify-center m-auto'}>
                <IconButton
                    onClick={() => onClickChangeSliderButton('next')}
                    icon={<img src={RightArrowIcon} alt={'next'}/>}
                />
            </div>
        </div>

    );
};

export default BodySizeSlider;
