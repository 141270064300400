// src/components/PublicRoute.tsx
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

interface PublicRouteProps {
  element: ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { token } = useAuth();

  return !token ? element : <Navigate to="/" replace />;
};

export default PublicRoute;
