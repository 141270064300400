import React from 'react';
import Layout from "../../screen/layout";
import {useTranslation} from "react-i18next";
import NotFoundImage from '../../../assets/images/not-found-404.png';

const NotFound = () => {
    const {t, i18n} = useTranslation();

    return (<Layout>
        <div
            className={'grid grid-cols-1 w-full p-10 justify-center text-center'}
            style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}
        >
            <img src={NotFoundImage} alt={'not-found'} className={'mx-auto mb-10 w-1/3'}/>
            <h1 className={'font-bold text-2xl my-1'}>{t("not_found_404_title")}</h1>
            <div className={'mx-auto text-start'}>
                <p className={'text-md my-1'}>{t("not_found_404_description")}</p>
                <p className={'text-md font-bold my-1'}>{t("not_found_404_tips_title")}</p>
                {t('not_found_404_tips', {returnObjects: true})?.map((tip: string) =>
                    <li className={'text-md text-start'}>{tip}</li>)
                }
            </div>
        </div>
    </Layout>)
}

export default NotFound;
