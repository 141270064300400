import {useEffect, useState} from "react";
import {usePostDataMutation, usePutDataMutation} from "../api/services/apiSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../redux/store";
import {
    setModalType,
    setDisplayModal,
    setModalData,
} from "../redux/slices/displayModalsSlice";
import useToast from "./useToast";
import {isEmailOrIranianPhoneNumber} from "../utils/validators";

export default function useAuth() {
    const [postApi, responsePostApi] = usePostDataMutation();
    const [putApi, responsePutApi] = usePutDataMutation();
    const getToken = (type: 'access' | 'refresh') => {
        const tokenString = localStorage.getItem("token");
        const userToken = tokenString ? JSON.parse(tokenString) : "";
        return userToken?.[type];
    };
    const {showToast, showToastMessage} = useToast();
    const [token, setToken] = useState(getToken('access'));
    const dispatch = useDispatch<AppDispatch>();

    const saveToken = (userToken: { refresh: string, access: string; }) => {
        localStorage.setItem("token", JSON.stringify(userToken));
        setToken(userToken.access);
    };

    const register = async (_emailAddressOrPhoneNumber: string) => {
        try {
            dispatch(setModalType("loading"));
            dispatch(setDisplayModal(true));

            let body: { email?: string; mobile_number?: string } = {};

            const typeResult = isEmailOrIranianPhoneNumber(_emailAddressOrPhoneNumber ?? '');

            if (typeResult.type === 'email') {
                body = {email: _emailAddressOrPhoneNumber};
            } else if (typeResult.type === 'phone') {
                body = {mobile_number: _emailAddressOrPhoneNumber};
            } else {
                return;
            }

            let response = await postApi({
                url: "users/register/",
                body: body,
            });

            if (response?.data?.status === 200) {
                showToast(response?.data?.message, "success");
                return true;
            } else {
                showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            dispatch(setDisplayModal(false));
        }
    };

    const verify = async (token: string, code: string) => {
        try {
            let response = await postApi({
                url: "users/verify-register/",
                body: {
                    token: token,
                    verification_code: code,
                },
            });
            if (response?.data?.status === 200) {
                saveToken(response?.data?.data);
                showToast(response?.data?.message, "success");
                dispatch(setDisplayModal(false));
                return true;
            } else {
                showToastMessage(response);
            }
        } catch (error) {
            console.error(error);
            const errorMessage =
                error instanceof Error ? error.message : String(error);
            showToast(errorMessage, "error");
            return false;
        }
    };

    const login = async (requestBody: LoginRequestBody | undefined) => {
        try {
            dispatch(setModalType("loading"));
            dispatch(setDisplayModal(true));

            let body: { password?: string; email?: string; mobile_number?: string } = {
                password: requestBody?.password,
            };

            const typeResult = isEmailOrIranianPhoneNumber(requestBody?.emailAddressOrPhoneNumber ?? '');

            if (typeResult.type === 'email') {
                body = {...body, email: requestBody?.emailAddressOrPhoneNumber};
            } else if (typeResult.type === 'phone') {
                body = {...body, mobile_number: requestBody?.emailAddressOrPhoneNumber};
            } else {
                return;
            }

            let response = await postApi({
                url: "users/login/",
                body: body,
            });
            return response?.data?.status === 200;
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            dispatch(setDisplayModal(false));
        }
    };

    const logout = async () => {
        try {
            dispatch(setModalType("loading"));
            dispatch(setDisplayModal(true));

            let response = await postApi({
                url: "users/logout/",
                body: {
                    refresh: getToken('refresh'),
                }
            });
            if (response?.data?.status === 200) {
                localStorage.removeItem("token");
                setToken(null);
                return true;
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            dispatch(setDisplayModal(false));
        }
    };

    const resetPasswordRequest = async (emailAddressOrPhoneNumber: string) => {
        try {
            dispatch(setModalType("loading"));
            dispatch(setDisplayModal(true));
            const typeResult = isEmailOrIranianPhoneNumber(emailAddressOrPhoneNumber ?? '');
            let body = null;
            if (typeResult.type === 'email') {
                body = {email: emailAddressOrPhoneNumber};
            } else if (typeResult.type === 'phone') {
                body = {mobile_number: emailAddressOrPhoneNumber};
            } else {
                return;
            }
            let response = await putApi({
                url: "users/reset-password/request",
                body: body
            });

            if (response?.data?.status === 200) {
                showToast(response?.data?.message, "success");
                return true;
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            dispatch(setDisplayModal(false));
        }
    };

    const completeResetPassword = async (resetPasswordRequestBody: ResetPasswordRequestBody) => {
        try {
            dispatch(setModalType("loading"));
            dispatch(setDisplayModal(true));
            let response = await putApi({
                url: "users/reset-password/validate",
                body: resetPasswordRequestBody
            });

            if (response?.data?.status === 200) {
                showToast(response?.data?.message, "success");
                return true;
            }
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            dispatch(setDisplayModal(false));
        }
    }

    useEffect(() => {
        if (responsePostApi?.isSuccess) {
            if (responsePostApi?.originalArgs?.url === "users/login/") {
                saveToken(responsePostApi?.data?.data);
            } else if (responsePostApi?.originalArgs?.url === "users/register/") {
                dispatch(
                    setModalData({
                        emailAddressOrPhoneNumber:
                        responsePostApi?.originalArgs?.body?.email,
                        token: responsePostApi?.data?.data?.token,
                    })
                );
                dispatch(setModalType("verify-register-by-otp"));
                dispatch(setDisplayModal(true));
            }
            responsePostApi?.reset();
        }
    }, [responsePostApi, dispatch]);

    return {
        token,
        logout,
        login,
        responsePostApi,
        responsePutApi,
        register,
        verify,
        resetPasswordRequest,
        completeResetPassword,
    };
}
