import React, {KeyboardEvent, useEffect, useState} from "react";
import Input from "../../../../atomic/input";
import Button from "../../../../atomic/buttons/button";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import {isEmailOrIranianPhoneNumber} from "../../../../../utils/validators";
import {setDisplayModal, setModalType} from "../../../../../redux/slices/displayModalsSlice";
import {useDispatch} from "react-redux";
import useToast from "../../../../../hooks/useToast";
import {useNavigate, useParams} from "react-router-dom";
import OTPInput from "../../../../composite/otp-input";

const ResetPasswordSection = () => {
    const {t, i18n} = useTranslation();
    const [resetPasswordRequestBody, setResetPasswordRequestBody] = useState<ResetPasswordRequestBody>({
        password: '',
        retype_password: '',
    });
    const {completeResetPassword} = useAuth();
    const navigate = useNavigate();
    const {showToast} = useToast();
    const {pin, token} = useParams();
    const [otpCode, setOtpCode] = useState<string | null>(null);

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        try {
            setResetPasswordRequestBody(
                resetPasswordRequestBody && {...resetPasswordRequestBody, [name]: value}
            );
        } catch (error) {
        }
    };

    const onClickResetPasswordButton = async () => {
        try {
            if (resetPasswordRequestBody?.password !== "" && resetPasswordRequestBody?.retype_password !== "") {
                let response = await completeResetPassword(resetPasswordRequestBody);
                if (response) {
                    navigate('/authorization')
                }
            } else {
                showToast(t("fill_inputs"), "error")
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (pin && token) {
            setResetPasswordRequestBody({
                ...resetPasswordRequestBody,
                token: token,
                pin: pin,
            })
        } else if (token) {
            setResetPasswordRequestBody({
                ...resetPasswordRequestBody,
                secret_key: token,
            });
            setOtpCode('')
        } else {
            showToast("Something went wrong!", "error")
            navigate('/authorization');
        }
    }, [pin, token]);

    return (
        <div className="grid gap-4 px-6">
            <Input
                onChange={(e) => onChangeInput(e)}
                value={resetPasswordRequestBody?.password ?? ''}
                placeholder={t("password")}
                type="password"
                name={"password"}
            />
            <Input
                onChange={(e) => onChangeInput(e)}
                value={resetPasswordRequestBody?.retype_password ?? ''}
                placeholder={t("retype_password")}
                type="password"
                name={"retype_password"}
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        onClickResetPasswordButton();
                    }
                }}
            />
            {otpCode && <div className="flex flex-col" style={{direction: i18n.language === 'fa' ? 'rtl' : 'ltr'}}>
                <p className={'text-xs text-gray-500'}>
                    {t("otp_code_hint")}
                </p>
                <OTPInput numDigits={5} onComplete={setOtpCode}/>
            </div>}
            <Button
                customClassName="w-full"
                disabled={resetPasswordRequestBody?.password === "" || resetPasswordRequestBody?.retype_password === ""}
                title={t("submit")}
                onClick={() => onClickResetPasswordButton()}
            />
        </div>
    );
};

export default ResetPasswordSection;
