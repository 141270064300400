import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Input from "../../atomic/input";
import PrivateInformationImage from "../../../assets/images/private-information-banner.png";
import Button from "../../atomic/buttons/button";
import useUsers from "../../../hooks/useUsers";
import FadeInText from "../../atomic/text/fade-in-text";

const PrivateInformation = () => {
    const {t, i18n} = useTranslation();
    const {updateProfile, getUserProfile, userProfile, responsePutApi} = useUsers();
    const [currentUserProfile, setCurrentUserProfile] = useState<UserProfile>();

    const onClickUpdateProfileButton = async () => {
        try {
            await updateProfile({
                firstName: currentUserProfile?.first_name ?? "",
                lastName: currentUserProfile?.last_name ?? "",
                emailAddress: currentUserProfile?.email ?? "",
                mobileNumber: currentUserProfile?.mobile_number ?? "",
                initialUpdate: false,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        try {
            setCurrentUserProfile(
                currentUserProfile && {...currentUserProfile, [name]: value}
            );
        } catch (error) {
        }
    };

    useEffect(() => {
        getUserProfile();
    }, []);

    useEffect(() => {
        if (userProfile) {
            setCurrentUserProfile(userProfile);
        }
    }, [userProfile]);
    return (
        <div
            className={`p-6 ${i18n.language === "fa" ? "text-right" : "text-left"}`}
            style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}}
        >
            <h1 className="font-bold text-lg">{t("private_information")}</h1>
            <FadeInText>
                <p className="text-sm">{t("private_information_description")}</p>
            </FadeInText>
            <div className="grid grid-cols-4 mt-8 h-[75vh]" style={{direction: i18n.language === 'en' ? 'rtl' : 'ltr'}}>
                <div className="grid col-span-1 h-full">
                    <img src={PrivateInformationImage} alt={"model"} className="mt-auto"/>
                </div>
                <div className="grid col-span-1 col-start-3 gap-3 w-full h-fit">
                    <FadeInText delay={.1}>
                        <Input
                            value={currentUserProfile?.first_name ?? ""}
                            onChange={(e) => onChangeInput(e)}
                            label={t("first_name")}
                            placeholder={t("first_name")}
                            name={"first_name"}
                        />
                    </FadeInText>
                    <FadeInText delay={.2}>
                        <Input
                            value={currentUserProfile?.last_name ?? ""}
                            onChange={(e) => onChangeInput(e)}
                            label={t("last_name")}
                            placeholder={t("last_name")}
                            name="last_name"
                        />
                    </FadeInText>

                    <FadeInText delay={.3}>
                        <Input
                            value={currentUserProfile?.mobile_number ?? ""}
                            onChange={(e) => onChangeInput(e)}
                            label={t("mobile_number")}
                            placeholder={t("mobile_number")}
                            name="mobile_number"
                        /></FadeInText>
                    <FadeInText delay={.4}>
                        <Input
                            value={currentUserProfile?.email ?? ""}
                            onChange={(e) => onChangeInput(e)}
                            label={t("email")}
                            placeholder={t("email")}
                            name="email"
                        />
                    </FadeInText>
                    <FadeInText delay={.5}>
                        <Button
                            onClick={() => onClickUpdateProfileButton()}
                            title={t("update_information")}
                            customClassName="w-full"
                            loading={responsePutApi?.isLoading}
                        />
                    </FadeInText>
                </div>
            </div>
        </div>
    );
};

export default PrivateInformation;
