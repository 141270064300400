import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { gatewayAPI } from '../api/services/apiSlice';
import displayModalsReducer from './slices/displayModalsSlice';

const rootReducer = combineReducers({
  [gatewayAPI.reducerPath]: gatewayAPI.reducer,
  modalsManagement: displayModalsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(gatewayAPI.middleware),
});

// Optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export default store;
