import React from "react";
import {useTranslation} from "react-i18next";

interface FeatureComponentProps {
  title: string;
  description: string;
  imageSrc: string;
  addToRefs: (ref: HTMLElement | null) => void;
  reverse?: boolean;
}

const GuidelineCard: React.FC<FeatureComponentProps> = ({
  title,
  description,
  imageSrc,
  addToRefs,
  reverse,
}) => {
  const {i18n} = useTranslation()
  return (
    <div className={`flex ${reverse ? 'flex-col' : 'flex-col-reverse'} md:flex-row items-center mb-8 ipsGrid ipsGrid_collapsePhone`}
    >
      {reverse && (
        <div
          className="w-62 md:w-[40%] gs_reveal gs_reveal_fromLeft"
          ref={addToRefs}
        >
          <img width="479" src={imageSrc} alt="" />
        </div>
      )}
      <div className={`w-[57%] text-center ${reverse ? 'md:text-left' : 'md:text-right'}`}
           style={{direction: i18n?.language === 'fa' ? 'rtl' : 'ltr'}}>
        <h2 className="text-[1.5rem] gs_reveal" ref={addToRefs}>
          {title}
        </h2>
        <p className={`gs_reveal ${reverse ? 'md:pr-36' : 'md:pl-36'} text-[.9rem]`} ref={addToRefs}>
          {description}
        </p>
      </div>
      {!reverse && (
        <div
          className="w-62 md:w-[40%] gs_reveal gs_reveal_fromRight"
          ref={addToRefs}
        >
          <img width="479" src={imageSrc} alt="" />
        </div>
      )}
    </div>
  );
};

export default GuidelineCard;
